import fetchData from "shared/data/fetchData"

const fetchEmployee = async (id, auth) => {
  const endpoint = `employees/${id}`

  try {
    const employee = await fetchData({
      endpoint,
      token: auth.token,
      dataKey: "employee",
    })

    return employee
  } catch (err) {
    console.error("Error fetching routes:", err)
    throw err // Propagate error to the caller
  }
}

export default fetchEmployee
