import React from "react"

const ErrorHandler = ({ errors }) => {
  if (!errors || errors.length === 0) return null

  return (
    <div className="notification is-danger is-light">
      {errors.map((error, index) => (
        <div key={index}>{error}</div>
      ))}
    </div>
  )
}

export default ErrorHandler
