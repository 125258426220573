import { React, useState, useEffect, useContext, useRef } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { AuthContext } from "shared/context/auth-context"
import Loader from "shared/components/UIElements/Loader"
import axios from "axios"
import RowSelectTable from "shared/components/DataTable/RowSelectTable"

const ViewCompany = () => {
  const { id } = useParams()
  const auth = useContext(AuthContext)
  const navigate = useNavigate()
  const [error, setError] = useState(null)
  const [company, setCompany] = useState(null)
  const [routes, setRoutes] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    document.title = "Ver Empresa"

    if (!auth.token) {
      return
    }

    getCompany()
    getCompanyRoutes()
  }, [auth.token, id, navigate, auth.user])

  const getCompany = async () => {
    setIsLoading(true)

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/companies/${id}`,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      )

      if (response.status === 200) {
        setCompany(response.data.company)
      }
    } catch (err) {
      setError(err.response.data.error)
    } finally {
      setIsLoading(false)
    }
  }

  const getCompanyRoutes = async () => {
    setIsLoading(true)

    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
        baseURL: `${process.env.REACT_APP_API_URL}/routes/company/${id}`,
        method: "GET",
      })

      if (response.status === 200) {
        setRoutes(response.data.routes)
      }
    } catch (err) {
      if (err.response.status === 401) {
        auth.logout()
      }
      setError(err.response.data.message)
    } finally {
      setIsLoading(false)
    }
  }

  const headers = [
    {
      Header: "Nombre",
      accessor: "name",
    },
  ]

  return (
    <div>
      <h1>{company && company.name} - Exportar rutas finalizadas</h1>
      {error && <p className="notification is-danger is-light">{error}</p>}
      {isLoading && <Loader asOverlay />}
      <div className="card-mibus">
        {routes && <RowSelectTable dataHeaders={headers} dataBody={routes} />}
      </div>
    </div>
  )
}

export default ViewCompany
