import React, { useMemo, useContext } from "react"
import { mdiBookEdit, mdiFileExcel } from "@mdi/js"
import { AuthContext } from "shared/context/auth-context"
import { NavLink } from "react-router-dom"
import useFetchCompanies from "shared/hooks/useFetchCompanies"
import Table from "shared/components/DataTable/Table"
import Icon from "@mdi/react"
import Loader from "shared/components/UIElements/Loader"

import "./Companies.css"

const Companies = () => {
  const auth = useContext(AuthContext)
  const { companies, isLoading, error } = useFetchCompanies(auth)

  const headers = useMemo(
    () => [
      {
        Header: "Logo",
        accessor: "image",
        Cell: ({ value }) =>
          value ? (
            <img
              src={`${process.env.REACT_APP_IMAGES_URL}/${value}`}
              alt="logo"
              height="30"
              width="30"
            />
          ) : null,
      },
      {
        Header: "Nombre",
        accessor: "name",
      },
      {
        Header: "Encargado",
        accessor: "userParent",
        Cell: ({ value }) =>
          value ? `${value.name || ""} ${value.lastname || ""}`.trim() : "N/A",
      },
      {
        Header: "",
        accessor: "id",
        Cell: ({ value }) => (
          <>
            <NavLink className="deactivate" to={`/companies/edit/${value}`}>
              <Icon path={mdiBookEdit} size={1} />
            </NavLink>
            <NavLink className="deactivate" to={`/companies/view/${value}`}>
              <Icon path={mdiFileExcel} size={1} />
            </NavLink>
          </>
        ),
      },
    ],
    []
  )

  return (
    <div>
      <h1 className="title">Compañías</h1>
      <div className="actions">
        <NavLink to="/companies/create">
          <button className="button is-primary">Registrar Empresa</button>
        </NavLink>
      </div>
      <div>
        {isLoading && <Loader asOverlay />}
        <div className="card-mibus">
          {error && (
            <div className="notification is-danger is-light">
              Error: {error}
            </div>
          )}
          <Table dataHeaders={headers} dataBody={companies} />
        </div>
      </div>
    </div>
  )
}

export default Companies
