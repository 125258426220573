import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import fetchCompany from "../data/companies/fetchCompany"

const useFetchCompany = (id, auth) => {
  const navigate = useNavigate()
  const [company, setCompany] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    const loadCompany = async () => {
      setIsLoading(true)
      try {
        const companyData = await fetchCompany(id, auth)
        setCompany(companyData)
      } catch (err) {
        if (err.response?.status === 401) {
          auth.logout()
        } else {
          setError(err.response?.data?.message || "An error occurred")
        }
      } finally {
        setIsLoading(false)
      }
    }

    loadCompany()
  }, [id, auth, navigate, setError, setIsLoading])

  return { company, isLoading, error }
}

export default useFetchCompany
