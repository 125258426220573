import fetchData from "shared/data/fetchData"

const fetchBusinessUsers = async (auth) => {
  const endpoint = "users/type/business"

  if (auth.user.role !== "admin") {
    return []
  }

  try {
    const users = await fetchData({
      endpoint,
      token: auth.token,
      dataKey: "users",
    })

    return users
  } catch (err) {
    console.error("Error fetching users:", err)
    throw err // Propagate error to the caller
  }
}

export default fetchBusinessUsers
