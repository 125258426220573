import React, { useEffect, useContext, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { AuthContext } from "shared/context/auth-context"
import GoogleMapReact from "google-map-react"
import polyline from "@mapbox/polyline"
import Loader from "shared/components/UIElements/Loader"
import axios from "axios"
import useFetchCompanies from "shared/hooks/useFetchCompanies"

const ViewGeneral = () => {
  const auth = useContext(AuthContext)
  const navigate = useNavigate()
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(null)
  const [error, setError] = useState(null)
  const [response, setResponse] = useState(null)

  const { companies, isCompaniesLoading, companiesError } =
    useFetchCompanies(auth)

  useEffect(() => {
    document.title = "Rutas"
    if (!auth.token) {
      return
    }

    if (auth.user.role !== "company") {
      getRoutesByCompany(id)
    }
  }, [auth.token])

  const getRoutesByCompany = async (companyId) => {
    setIsLoading(true)

    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
        baseURL: `${process.env.REACT_APP_API_URL}/routes/company/${companyId}`,
        method: "GET",
      })

      if (response.status === 200) {
        setResponse(response.data)
      } else {
        setError(response.data.error)
      }
    } catch (err) {
      if (err.response.status === 401) {
        auth.logout()
      }
      setError(err.response.data.message)
    }
    setIsLoading(false)
  }

  const defaultCenter = { lat: 19.427, lng: -99.133 }
  const defaultZoom = 14

  const getRandomColor = () => {
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`
  }

  const renderPolylinesAndMarkers = (map, maps, routes) => {
    const bounds = new maps.LatLngBounds() // Create bounds object
    const polylines = [] // Store references to all polylines

    // Helper to create custom labels with background
    const createCustomLabel = (position, text) => {
      const div = document.createElement("div")
      div.style.position = "absolute"
      div.style.transform = "translate(-50%, -50%)" // Center the label
      div.style.backgroundColor = "white" // White background
      div.style.borderRadius = "4px" // Rounded corners
      div.style.padding = "5px" // Padding around text
      div.style.boxShadow = "0 2px 4px rgba(0, 0, 0, 0.2)" // Optional shadow
      div.style.color = "black" // Text color
      div.style.fontWeight = "bold" // Bold text
      div.style.fontSize = "125%" // Smaller font size
      div.style.zIndex = "10" // High zIndex to ensure it appears above other layers
      div.innerText = text

      const overlay = new window.google.maps.OverlayView()
      overlay.onAdd = function () {
        const pane = this.getPanes().overlayMouseTarget // Use a higher layer for interactivity
        pane.appendChild(div)
      }
      overlay.draw = function () {
        const point = this.getProjection().fromLatLngToDivPixel(position)
        if (point) {
          div.style.left = `${point.x}px`
          div.style.top = `${point.y}px`
        }
      }
      overlay.onRemove = function () {
        div.parentNode.removeChild(div)
      }

      overlay.setMap(map)
    }

    routes.forEach((route) => {
      const routeColor = getRandomColor()

      if (route.overview) {
        const decodedPath = polyline.decode(route.overview)
        const path = decodedPath.map(([lat, lng]) => ({ lat, lng }))

        // Extend bounds for each polyline point
        path.forEach((point) => {
          bounds.extend(new maps.LatLng(point.lat, point.lng))
        })

        // Create the polyline
        const polylinePath = new maps.Polyline({
          path,
          geodesic: true,
          strokeColor: routeColor,
          strokeOpacity: 1.0,
          strokeWeight: 8, // Initial width
          zIndex: 1,
        })
        polylinePath.setMap(map)

        // Store the polyline reference
        polylines.push(polylinePath)

        // Add a custom label for the route name
        const midpointIndex = Math.floor(path.length / 2)
        const midpoint = new maps.LatLng(
          path[midpointIndex].lat,
          path[midpointIndex].lng
        )

        createCustomLabel(midpoint, route.name)
      }

      // Add markers for each point
      route.points.forEach((point) => {
        const markerPosition = {
          lat: parseFloat(point.lat),
          lng: parseFloat(point.lng),
        }
        bounds.extend(new maps.LatLng(markerPosition.lat, markerPosition.lng)) // Extend bounds

        new maps.Marker({
          position: markerPosition,
          map: map,
          label: {
            color: "white",
            fontWeight: "bold",
            text: point.name,
            className: "marker-label",
          },
          icon: {
            labelOrigin: new maps.Point(11, 50),
            size: new maps.Size(22, 40),
            origin: new maps.Point(0, 0),
            anchor: new maps.Point(11, 40),
          },
        })
      })
    })

    // Fit the map to the calculated bounds
    map.fitBounds(bounds)
  }

  // redirect to page when company is selected
  const onChangeHandler = (event) => {
    const value = event.target.value
    if (value) {
      window.location.href = `/routes/general/${value}`
    }
  }

  return (
    (response && (
      <div style={{ height: "75vh", width: "100%" }}>
        {companies && (
          <div className="form-group">
            <select className="form-control" onChange={onChangeHandler}>
              <option value="">Seleccione un usuario</option>
              {companies.map((company) => (
                <option key={company.id} value={company.id}>
                  {company.name}
                </option>
              ))}
            </select>
          </div>
        )}
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
          defaultCenter={defaultCenter}
          defaultZoom={defaultZoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) =>
            renderPolylinesAndMarkers(map, maps, response.routes)
          }
        />
      </div>
    )) ||
    (isLoading && <Loader asOverlay />) || // Show loader while loading
    (error && <p className="notification is-danger is-light">{error}</p>)
  ) // Show error message if any
}

export default ViewGeneral
