import React, { useState, useContext, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { AuthContext } from "shared/context/auth-context"
import { useForm } from "shared/hooks/formHook"
import axios from "axios"
import Input from "shared/components/FormElements/Input"
import Loader from "shared/components/UIElements/Loader"
import fetchBusinessUsers from "shared/data/users/fetchBusinessUsers"
import fetchCompanies from "shared/data/companies/fetchCompanies"
import fetchUser from "shared/data/users/fetchUser"
import ErrorHandler from "shared/components/ErrorHandler"
import {
  VALIDATOR_REQUIRED,
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
} from "shared/utils/validator"

const EditUser = () => {
  const { id } = useParams()
  const auth = useContext(AuthContext)
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [errors, setErrors] = useState([])
  const [user, setUser] = useState(null)
  const [businessUsers, setBusinessUsers] = useState([])
  const [companies, setCompanies] = useState([])
  const [rol, setRol] = useState("company")
  const [parent, setParent] = useState(null)
  const [formState, inputHandler] = useForm({
    name: {
      value: "",
      isValid: false,
    },
    lastname: {
      value: "",
      isValid: false,
    },
    userParent: {
      value: "",
      isValid: false,
    },
    role: {
      value: "",
      isValid: false,
    },
    company: {
      value: "",
      isValid: false,
    },
    email: {
      value: "",
      isValid: false,
    },
    password: {
      value: "",
      isValid: false,
    },
    repassword: {
      value: "",
      isValid: false,
    },
  })

  useEffect(() => {
    document.title = "Editar Usuario"
    if (!auth.token) {
      return
    }

    if (auth.user.role === "company") {
      navigate("/panel", { replace: true })
    } else if (auth.user.role === "admin") {
      const loadBusinessUsers = async () => {
        const users = await fetchBusinessUsers(
          auth,
          navigate,
          setError,
          setIsLoading
        )
        setBusinessUsers(users)
      }
      loadBusinessUsers()

      const loadCompanies = async () => {
        setIsLoading(true)
        try {
          const fetchedCompanies = await fetchCompanies(auth)
          if (fetchedCompanies) {
            setCompanies(fetchedCompanies)
          }
        } catch (err) {
          if (err.response?.status === 401) {
            auth.logout()
          } else {
            setError(err.response?.data?.message || "An error occurred")
          }
        } finally {
          setIsLoading(false)
        }
      }
      loadCompanies()
    }

    const loadUser = async () => {
      const userData = await fetchUser(
        id,
        auth,
        navigate,
        setError,
        setIsLoading
      )
      if (userData) {
        setUser(userData)
        setRol(userData.role)
        if (userData.userParent !== null && userData.userParent !== undefined) {
          setParent(userData.userParent)
          getCompaniesByUserParent(userData.userParent)
        }
      }
    }
    loadUser()
  }, [id, auth, navigate, setError, setIsLoading])

  const getCompaniesByUser = async (parent) => {
    setIsLoading(true)

    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
        baseURL: `${process.env.REACT_APP_API_URL}/companies/business/${parent}`,
        method: "GET",
      })

      if (response.status === 200) {
        setCompanies(response.data.companies)
      }
    } catch (err) {
      if (err.response.status === 401) {
        auth.logout()
      }
      setError(err.response.data.message)
    }
    setIsLoading(false)
  }

  const getCompaniesByUserParent = (user) => {
    if (user !== "") {
      getCompaniesByUser(user)
    }
  }

  const updateUser = async () => {
    setIsLoading(true)

    const userParentId =
      rol === "company"
        ? formState.inputs.userParent.value
        : rol === "business"
        ? auth.user.id
        : null

    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
        baseURL: `${process.env.REACT_APP_API_URL}/users/${id}`,
        method: "PUT",
        data: {
          name: formState.inputs.name.value,
          lastname: formState.inputs.lastname.value,
          role: auth.user.role === "admin" ? formState.inputs.role.value : rol,
          email: formState.inputs.email.value,
          password: formState.inputs.password.value,
          repassword: formState.inputs.repassword.value,
          ...(rol === "company" && {
            user_parent_id: userParentId,
            company_id: formState.inputs.company.value,
          }),
        },
      })

      if (response.status === 200) {
        navigate("/users", { replace: true })
      } else {
        setError("Ocurrío un error inesperado")
      }
    } catch (err) {
      const errorMessages = err.response?.data.errors || ["An error occurred"]
      setErrors(errorMessages)
    }

    setIsLoading(false)
  }

  return (
    <div>
      {isLoading && <Loader asOverlay />}
      <h1 className="title">Editar Usuario {user && `"${user.name}"`}</h1>
      <div className="actions buttons">
        <button className="button is-success" onClick={updateUser}>
          Actualizar
        </button>
        <button className="button is-danger" onClick={() => navigate(-1)}>
          Cancelar
        </button>
      </div>
      <ErrorHandler errors={errors} />
      {error && <p className="notification is-danger is-light">{error}</p>}
      <div className="card-mibus">
        {user && (
          <div className="columns">
            <div className="column">
              <div className="form-group">
                <Input
                  type="text"
                  id="name"
                  label="Nombre"
                  value={user.name}
                  placeholder="Nombre"
                  className="form-control"
                  onInput={inputHandler}
                  errorText="El nombre es obligatorio"
                  validators={[VALIDATOR_REQUIRED()]}
                />
              </div>
              <div className="form-group">
                <Input
                  type="text"
                  id="lastname"
                  label="Apellido"
                  value={user.lastname}
                  placeholder="Apellido"
                  className="form-control"
                  onInput={inputHandler}
                  errorText="El apellido es obligatorio"
                  validators={[VALIDATOR_REQUIRED()]}
                />
              </div>
              <div className="form-group">
                <Input
                  type="select"
                  id="role"
                  label="Rol"
                  element="select"
                  value={user.role}
                  onInput={inputHandler}
                  callFunction={setRol}
                  errorText="El rol es obligatorio"
                  validators={[VALIDATOR_REQUIRED()]}
                >
                  <option value="">Seleccionar</option>
                  <option value="admin">Administrador</option>
                  <option value="business">Transportista</option>
                  <option value="company">Compañía</option>
                </Input>
              </div>
              {user && rol === "company" && auth.user.role !== "business" && (
                <div className="form-group">
                  <Input
                    type="select"
                    id="userParent"
                    label="Usuario Padre"
                    element="select"
                    value={user.user_parent_id}
                    onInput={inputHandler}
                    callFunction={getCompaniesByUserParent}
                    errorText="El padre es obligatorio para los usuarios de tipo compañía"
                    validators={[VALIDATOR_REQUIRED()]}
                  >
                    <option value="">Seleccione un usuario</option>
                    {businessUsers &&
                      businessUsers.map((businessUser) => (
                        <option key={businessUser.id} value={businessUser.id}>
                          {businessUser.name} {businessUser.lastname}
                        </option>
                      ))}
                  </Input>
                </div>
              )}
              {user && rol === "company" && (
                <div className="form-group">
                  <Input
                    type="select"
                    id="company"
                    element="select"
                    onInput={inputHandler}
                    value={user.company_id}
                    errorText="La compañía es obligatoria para los usuarios de tipo compañía"
                    validators={[VALIDATOR_REQUIRED()]}
                    label="Compañía (solo para usuarios de tipo compañía)"
                  >
                    <option value="">Seleccionar</option>
                    {companies.map((company) => (
                      <option key={company.id} value={company.id}>
                        {company.name}
                      </option>
                    ))}
                  </Input>
                </div>
              )}
            </div>
            <div className="column">
              <div className="form-group">
                <Input
                  type="text"
                  id="email"
                  value={user.email}
                  label="Correo Electrónico"
                  placeholder="Correo electrónico"
                  className="form-control"
                  onInput={inputHandler}
                  errorText="El correo electrónico es obligatorio"
                  validators={[VALIDATOR_REQUIRED(), VALIDATOR_EMAIL()]}
                />
              </div>
              <div className="form-group">
                <Input
                  type="password"
                  id="password"
                  element="password"
                  label="Contraseña (Llena solo si deseas cambiarla)"
                  placeholder="Contraseña"
                  className="form-control"
                  onInput={inputHandler}
                  errorText="La contraseña debe tener al menos 8 caracteres"
                  validators={[VALIDATOR_MINLENGTH(8)]}
                />
              </div>
              <div className="form-group">
                <Input
                  type="password"
                  id="repassword"
                  element="password"
                  placeholder="Confirmar Contraseña"
                  label="Confirmar Contraseña"
                  className="form-control"
                  onInput={inputHandler}
                  errorText="La contraseña debe tener al menos 8 caracteres"
                  validators={[VALIDATOR_MINLENGTH(8)]}
                />
              </div>
            </div>
            <div className="column">
              <img src="" alt="" />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default EditUser
