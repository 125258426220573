import fetchData from "shared/data/fetchData"

const fetchRoutes = async (auth, companyId) => {
  const endpoint = `routes/company/${companyId}`

  try {
    const routes = await fetchData({
      endpoint,
      token: auth.token,
      dataKey: "routes",
    })

    return routes
  } catch (err) {
    console.error("Error fetching routes:", err)
    throw err // Propagate error to the caller
  }
}

export default fetchRoutes
