import { React, useContext, useState } from "react"
import { NavLink } from "react-router-dom"
import { AuthContext } from "shared/context/auth-context"
import {
  mdiAccountOutline,
  mdiFileExcel,
  mdiViewDashboardVariant,
  mdiDomain,
  mdiGoogleMaps,
  mdiCardAccountDetails,
  mdiAccountHardHat,
  mdiLogout,
  mdiClose,
} from "@mdi/js"
import logo from "logo.png"
import Icon from "@mdi/react"
import avatar from "user.png"

import "./Sidebar.css"

const Sidebar = () => {
  const auth = useContext(AuthContext)
  const [sidebarRight, setSidebarRight] = useState(false)

  const onOffSidebarRight = () => {
    setSidebarRight(!sidebarRight)
  }

  const logout = () => {
    auth.logout()
    auth.logout()
  }

  return (
    <>
      {!auth.token ? (
        <div className="no_auth">
          <h1>401</h1>
          <p>No autorizado</p>
          <p>
            <NavLink to="/">
              <button style={{ marginTop: "50px" }} className="button">
                Iniciar sesión
              </button>
            </NavLink>
          </p>
        </div>
      ) : (
        <div className="auth"></div>
      )}
      <div className="nav">
        Bienvenido{" "}
        <b style={{ cursor: "pointer" }} onClick={onOffSidebarRight}>
          {auth && auth.user.name && `${auth.user.name} `}
          {auth && auth.user.lastname && `${auth.user.lastname}`}
          {auth && auth.user.image ? (
            <img
              src={auth.user.image}
              width="20"
              style={{ marginLeft: "10px" }}
              alt=""
            />
          ) : (
            <img
              src={avatar}
              width="20"
              style={{ marginLeft: "10px" }}
              alt=""
            />
          )}
        </b>
      </div>
      <div className="sidebar-container">
        <div className="sidebar">
          <div className="logo">
            {auth &&
            auth.user.role === "company" &&
            auth.user.image !== undefined &&
            auth.user.image !== "" ? (
              <img
                src={`http://api.mytrackingbus.com/${auth.user.image}`}
                alt="logo"
              />
            ) : (
              <img src={logo} alt="" />
            )}
          </div>
          <ul>
            <li>
              <NavLink to="/panel">
                <Icon path={mdiViewDashboardVariant} size={1} />
                <span>Dashboard</span>
              </NavLink>
            </li>
            {auth.user && auth.user.role !== "company" && (
              <>
                <li>
                  <NavLink to="/users">
                    <Icon path={mdiAccountOutline} size={1} />
                    <span>Usuarios</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/companies">
                    <Icon path={mdiDomain} size={1} />
                    <span>Compañías</span>
                  </NavLink>
                </li>
              </>
            )}
            <li>
              <NavLink to="/routes">
                <Icon path={mdiGoogleMaps} size={1} />
                <span>Rutas</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/drivers">
                <Icon path={mdiCardAccountDetails} size={1} />
                <span>Conductores</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/employees">
                <Icon path={mdiAccountHardHat} size={1} />
                <span>Empleados</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/import">
                <Icon path={mdiFileExcel} size={1} />
                <span>Importar Usuarios</span>
              </NavLink>
            </li>
            <li>
              <span className="link" onClick={() => logout()}>
                <Icon path={mdiLogout} size={1} />
                <span>Cerrar sesión</span>
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className={`sidebar-right ${sidebarRight}`}>
        <div className="sidebar-right-header">
          <Icon
            style={{ cursor: "pointer" }}
            path={mdiClose}
            size={1.5}
            onClick={onOffSidebarRight}
          />
        </div>
        <div className="sidebar-right-content">
          <ul>
            <li>
              {auth && auth.user.image !== "" ? (
                <img src={auth.user.image} alt="avatar" />
              ) : (
                <img src={avatar} alt="avatar" />
              )}
            </li>
            <li>
              Nombre:{" "}
              <b>
                {auth && auth.user.name} {auth.user.lastname}
              </b>
            </li>
            <li>
              Email: <b>{auth.user.email}</b>
            </li>
            <li>
              Rango: <b>{auth.user.role}</b>
            </li>
            {auth.user.company && (
              <li>
                Compañía: <b>{auth.user.company.name}</b>
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  )
}

export default Sidebar
