import React, { useState, useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { NavLink } from "react-router-dom"
import {
  mdiBookEdit,
  mdiCheckboxBlankCircleOutline,
  mdiCheckboxMarkedCircle,
  mdiClipboardTextClock,
  mdiDelete,
  mdiPrinter,
} from "@mdi/js"
import { AuthContext } from "shared/context/auth-context"
import Table from "shared/components/DataTable/Table"
import Icon from "@mdi/react"
import Loader from "shared/components/UIElements/Loader"
import axios from "axios"

import "./Routes.css"
import Pagination from "widgets/Pagination"

const Routes = () => {
  const auth = useContext(AuthContext)
  const navigate = useNavigate()
  const [routes, setRoutes] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState([])
  const [companies, setCompanies] = useState([])
  const [isLoading, setIsLoading] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    document.title = "Rutas"
    if (!auth.token) {
      return
    }

    if (auth.user.role !== "company") {
      getCompanies()
    }
    if (auth.user.role !== "business") {
      getRoutes(currentPage)
    }
  }, [auth.token])

  const getCompanies = async () => {
    setIsLoading(true)

    let url
    if (auth.user.role === "business") {
      url = `${process.env.REACT_APP_API_URL}/companies/business/${auth.user.id}`
    } else {
      url = `${process.env.REACT_APP_API_URL}/companies/`
    }

    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
        baseURL: url,
        method: "GET",
      })

      if (response.status === 200) {
        setCompanies(response.data.companies)
      }
    } catch (err) {
      if (err.response.status === 401) {
        auth.logout()
      }
      setError(err.response.data.message)
    }
    setIsLoading(false)
  }

  const getRoutes = async (page) => {
    setIsLoading(true)
    let endpoint = ""

    if (auth.user.role === "company") {
      endpoint = `/routes/company/${auth.user.company_id}`
    } else if (auth.user.role === "admin") {
      endpoint = `/routes?page=${page}`
    }

    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
        baseURL: `${process.env.REACT_APP_API_URL}${endpoint}`,
        method: "GET",
      })

      if (response.status === 200) {
        setRoutes(response.data.routes)
        setTotalPages(response.data.pages)
        setCurrentPage(response.data.currentPage)
      }
    } catch (err) {
      if (err.response.status === 401) {
        navigate("/")
      }
      setError(err.response.data.error)
    }
    setIsLoading(false)
  }

  const getRoutesByCompany = async (companyId) => {
    if (companyId === "all") {
      getRoutes()
      return
    }

    setIsLoading(true)

    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
        baseURL: `${process.env.REACT_APP_API_URL}/routes/company/${companyId}`,
        method: "GET",
      })

      if (response.status === 200) {
        setRoutes(response.data.routes)
        setTotalPages(response.data.pages)
        setCurrentPage(response.data.currentPage)
      } else {
        setError(response.data.error)
      }
    } catch (err) {
      if (err.response.status === 401) {
        auth.logout()
      }
      setError(err.response.data.message)
    }
    setIsLoading(false)
  }

  const deleteRoute = async (routeId) => {
    if (!window.confirm("¿Estás seguro de eliminar esta ruta?")) {
      return
    }

    if (!auth.token) {
      return
    }

    if (auth.user.role !== "admin") {
      return
    }

    setIsLoading(true)
    const response = await axios({
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
      baseURL: `${process.env.REACT_APP_API_URL}/routes/${routeId}`,
      method: "DELETE",
    })

    if (response.status === 200) {
      getRoutes()
    }
    setIsLoading(false)
  }

  const headers = [
    {
      Header: "Nombre",
      accessor: "name",
    },
    {
      Header: "Estado",
      accessor: "active",
      Cell: (row) => (
        <>
          {row.value ? (
            <Icon path={mdiCheckboxMarkedCircle} size={1} color="#73c67a" />
          ) : (
            <Icon path={mdiCheckboxBlankCircleOutline} size={1} color="#ccc" />
          )}
        </>
      ),
    },
    {
      Header: "Acciones",
      accessor: "id",
      Cell: (row) => (
        <>
          <NavLink className="deactivate" to={`/routes/view/${row.value}`}>
            <Icon
              path={mdiClipboardTextClock}
              size={1}
              style={{ marginRight: "10px" }}
              color="green"
              onClick={() => {}}
            >
              History
            </Icon>
          </NavLink>
          <NavLink className="deactivate" to={`/routes/edit/${row.value}`}>
            <Icon path={mdiBookEdit} size={1} style={{ marginRight: "10px" }}>
              Edit
            </Icon>
          </NavLink>
          <NavLink className="deactivate" to={`/routes/print/${row.value}`}>
            <Icon path={mdiPrinter} size={1} style={{ marginRight: "10px" }}>
              Print
            </Icon>
          </NavLink>
          <Icon
            className="deactivate"
            path={mdiDelete}
            size={1}
            onClick={() => deleteRoute(row.value)}
            style={{ cursor: "pointer" }}
            color="#ff0000"
          >
            Delete
          </Icon>
        </>
      ),
    },
  ]

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage)
  }

  return (
    <div>
      <h1 className="title">Rutas</h1>
      <div className="actions buttons" style={{ marginBottom: "0px" }}>
        {auth && auth.user.role !== "company" && (
          <div>
            <NavLink to="/routes/create">
              <button className="button is-primary">Registrar Ruta</button>
            </NavLink>
            <NavLink to="/routes/general/1">
              <button className="button is-warning">Vista General</button>
            </NavLink>
          </div>
        )}
      </div>
      <div>
        {isLoading && <Loader asOverlay />}
        <div className="card-mibus">
          {error && (
            <div className="columns">
              <div className="column">
                <div className="notification is-danger is-light">
                  Error: {error}
                </div>
              </div>
            </div>
          )}
          {companies && (
            <Table
              dataCompanies={
                auth.user && auth.user.role !== "company" ? companies : null
              }
              cb={getRoutesByCompany}
              dataHeaders={headers}
              dataBody={routes}
            />
          )}
          <div>
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Routes
