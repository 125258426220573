import React, { useEffect, useContext, useState } from "react"
import { AuthContext } from "shared/context/auth-context"
import Loader from "shared/components/UIElements/Loader"
import DashTable from "../widgets/DashTable"
import DoughnutChart from "../widgets/DoughnutChart"
import BarChart from "../widgets/BarChart"
import axios from "axios"
import SimpleBarChart from "dashboard/widgets/SimpleBarChart"

const Dashboard = () => {
  const auth = useContext(AuthContext)
  const [routes, setRoutes] = useState([])
  const [isLoading, setIsLoading] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    document.title = "Panel de control"
    if (!auth.token) {
      return
    }
    getRoutes()
  }, [auth.token])

  const getRoutes = async () => {
    setIsLoading(true)
    let endpoint = ""

    if (auth.user.role === "company") {
      endpoint = `/routes/company/${auth.user.company_id}`
    } else if (auth.user.role === "admin") {
      endpoint = "/routes?limit=10"
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${endpoint}`,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      )

      if (response.status === 200) {
        setRoutes(response.data.routes)
      } else if (response.status === 401) {
        auth.logout()
      }
    } catch (e) {
      setError(e.response?.data?.error || "An error occurred")
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <h1 className="title">Dashboard</h1>
      {isLoading && <Loader asOverlay />}
      <div className="columns">
        <div className="column is-one-quarter-desktop">
          <DoughnutChart routes={routes} />
        </div>
        <div className="column is-one-third-desktop">
          <BarChart routes={routes} />
        </div>
        <div className="column is-one-third-desktop">
          <SimpleBarChart routes={routes} />
        </div>
      </div>
      <div className="columns">
        <div className="column is-12-desktop">
          {routes && routes.length > 0 && <DashTable routes={routes} />}
        </div>
      </div>
    </div>
  )
}

export default Dashboard
