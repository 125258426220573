import React, { useState, useContext, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { AuthContext } from "shared/context/auth-context"
import { useForm } from "shared/hooks/formHook"
import { VALIDATOR_REQUIRED, VALIDATOR_EMAIL } from "shared/utils/validator"
import fetchCompanies from "shared/data/companies/fetchCompanies"
import fetchDriver from "shared/data/drivers/fetchDriver"
import fetchRoutes from "shared/data/routes/fetchRoutes"
import updateDriver from "shared/data/drivers/updateDriver"
import Input from "shared/components/FormElements/Input"
import Loader from "shared/components/UIElements/Loader"
import ErrorHandler from "shared/components/ErrorHandler"

const EditDriver = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const auth = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(false)
  const [driver, setDriver] = useState(null)
  const [companies, setCompanies] = useState([])
  const [routes, setRoutes] = useState([])
  const [error, setError] = useState(null)
  const [errors, setErrors] = useState(null)
  const [formState, inputHandler] = useForm({
    name: {
      value: "",
      isValid: false,
    },
    email: {
      value: "",
      isValid: false,
    },
  })

  useEffect(() => {
    document.title = "Editar Conductor"
    if (!auth.token) {
      return
    }

    if (auth.user.role !== "company") {
      const loadCompanies = async () => {
        setIsLoading(true)
        try {
          const fetchedCompanies = await fetchCompanies(auth)
          setCompanies(fetchedCompanies)
        } catch (err) {
          if (err.response?.status === 401) {
            auth.logout()
          } else {
            setError(err.response?.data?.message || "An error occurred")
          }
        } finally {
          setIsLoading(false)
        }
      }
      loadCompanies()
    }

    const loadDriver = async () => {
      const fetchedDriver = await fetchDriver(
        id,
        auth,
        navigate,
        setError,
        setIsLoading
      )
      setDriver(fetchedDriver)
    }
    loadDriver()

    const loadRoutes = async () => {
      const fetchedRoutes = await fetchRoutes(
        auth,
        navigate,
        setError,
        setIsLoading,
        id
      )
      setRoutes(fetchedRoutes)
    }
    loadRoutes()
  }, [id, auth, navigate, setError, setIsLoading])

  const submitHandler = async (event) => {
    event.preventDefault()
    setIsLoading(true)
    try {
      const response = await updateDriver(id, formState, auth)
      if (response.status === 201) {
        navigate("/drivers", { replace: true })
      }
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout()
      } else {
        setErrors(err.response?.data.errors || ["An error occurred"])
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <h1 className="title">Editar Conductor</h1>
      <div className="actions buttons" style={{ marginBottom: "0px" }}>
        <button className="button is-success" onClick={submitHandler}>
          Actualizar
        </button>
        <button className="button is-danger" onClick={() => navigate(-1)}>
          Cancelar
        </button>
      </div>
      <ErrorHandler errors={errors} />
      {isLoading && <Loader asOverlay />}
      <div className="card-mibus">
        {driver && (
          <div>
            <div className="columns">
              <div className="column">
                {companies &&
                  auth.user &&
                  auth.user.role !== "company" &&
                  (driver.Company !== null ? (
                    <div className="form-group">
                      <div style={{ display: "none" }}>
                        {JSON.stringify(driver.Company)}
                      </div>
                      <Input
                        id="company"
                        placeholder="Empresa"
                        label="Empresa"
                        className="form-control"
                        value={driver.Company.id}
                        element="select"
                        onInput={inputHandler}
                        validators={[VALIDATOR_REQUIRED()]}
                        errorText="Este campo es requerido"
                      >
                        <option value="">Seleccionar</option>
                        {companies.map((company) => {
                          return (
                            <option key={company.id} value={company.id}>
                              {company.name}
                            </option>
                          )
                        })}
                      </Input>
                    </div>
                  ) : (
                    <div className="form-group">
                      <Input
                        id="company"
                        placeholder="Empresa"
                        label="Empresa"
                        className="form-control"
                        element="select"
                        onInput={inputHandler}
                        validators={[VALIDATOR_REQUIRED()]}
                        errorText="Este campo es requerido"
                      >
                        <option value="">Seleccionar</option>
                        {companies.map((company) => {
                          return (
                            <option key={company.id} value={company.id}>
                              {company.name}
                            </option>
                          )
                        })}
                      </Input>
                    </div>
                  ))}
                <div className="form-group">
                  <Input
                    type="text"
                    id="name"
                    value={driver.name}
                    placeholder="Nombre"
                    label="Nombre"
                    className="form-control"
                    onInput={inputHandler}
                    validators={[VALIDATOR_REQUIRED()]}
                    errorText="Este campo es requerido"
                  />
                </div>
                <div className="form-group">
                  <Input
                    type="text"
                    id="lastname"
                    value={driver.lastname}
                    placeholder="Apellido"
                    label="Apellido"
                    className="form-control"
                    onInput={inputHandler}
                    validators={[VALIDATOR_REQUIRED()]}
                    errorText="Este campo es requerido"
                  />
                </div>
                <div className="form-group">
                  <Input
                    type="text"
                    id="registration"
                    value={driver.registration}
                    placeholder="Matrícula"
                    label="Matrícula"
                    className="form-control"
                    onInput={inputHandler}
                    validators={[VALIDATOR_REQUIRED()]}
                    errorText="Este campo es requerido"
                  />
                </div>
                <div className="form-group">
                  <Input
                    type="text"
                    id="license"
                    value={driver.license_type}
                    placeholder="Licencia"
                    label="Licencia"
                    className="form-control"
                    onInput={inputHandler}
                    validators={[VALIDATOR_REQUIRED()]}
                    errorText="Este campo es requerido"
                  />
                </div>
              </div>
              <div className="column">
                <div className="form-group">
                  <Input
                    type="text"
                    id="email"
                    value={driver.email}
                    placeholder="Correo electrónico"
                    label="Correo electrónico"
                    className="form-control"
                    onInput={inputHandler}
                    validators={[VALIDATOR_REQUIRED(), VALIDATOR_EMAIL()]}
                    errorText="Este campo debe ser un correo electrónico válido"
                  />
                </div>
                <div className="form-group">
                  <Input
                    type="password"
                    id="password"
                    element="password"
                    placeholder="Contraseña (Solo llenar para cambiar la anterior)"
                    label="Contraseña (Solo llenar para cambiar la anterior)"
                    className="form-control"
                    onInput={inputHandler}
                    validators={[]}
                  />
                </div>
                <div className="form-group">
                  <Input
                    type="password"
                    id="repassword"
                    element="password"
                    placeholder="Confirmar Contraseña"
                    label="Confirmar contraseña"
                    className="form-control"
                    onInput={inputHandler}
                    validators={[]}
                  />
                </div>
                {routes && (
                  <div className="form-group">
                    <Input
                      id="route"
                      placeholder="Ruta"
                      label="Ruta"
                      className="form-control"
                      element="select"
                      value={driver.route_id}
                      onInput={inputHandler}
                      validators={[]}
                    >
                      <option value="">Seleccionar</option>
                      {routes.map((route) => {
                        return (
                          <option key={route.id} value={route.id}>
                            {route.name}
                          </option>
                        )
                      })}
                    </Input>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default EditDriver
