import fetchData from "shared/data/fetchData"

const fetchCompanies = async (auth) => {
  const endpoint =
    auth.user.role === "admin"
      ? "companies"
      : `companies/business/${auth.user.id}`

  try {
    const companies = await fetchData({
      endpoint,
      token: auth.token,
      dataKey: "companies",
    })

    return companies
  } catch (err) {
    console.error("Error fetching companies:", err)
    throw err // Propagate error to the caller
  }
}

export default fetchCompanies
