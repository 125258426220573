import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import fetchBusinessUsers from "../data/users/fetchBusinessUsers"

const useFetchBusinessUsers = (auth) => {
  const navigate = useNavigate()
  const [businessUsers, setBusinessUsers] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    const loadBusinessUsers = async () => {
      setIsLoading(true)
      try {
        const fetchedBusinessUsers = await fetchBusinessUsers(auth)
        setBusinessUsers(fetchedBusinessUsers)
      } catch (err) {
        if (err.response?.status === 401) {
          auth.logout()
        } else {
          setError(err.response?.data?.message || "An error occurred")
        }
      } finally {
        setIsLoading(false)
      }
    }

    loadBusinessUsers()
  }, [auth, navigate, setError, setIsLoading])

  return { businessUsers, isLoading, error }
}

export default useFetchBusinessUsers
