import fetchData from "shared/data/fetchData"

const fetchCompany = async (id, auth) => {
  const endpoint = `companies/${id}`

  try {
    const company = await fetchData({
      endpoint,
      token: auth.token,
      dataKey: "company",
    })

    return company
  } catch (err) {
    console.error("Error fetching company:", err)
    throw err // Propagate error to the caller
  }
}

export default fetchCompany
