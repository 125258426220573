import axios from "axios"

const updateEmployee = async (id, formState, auth) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/drivers/${id}`,
      {
        name: formState.inputs.name.value,
        lastname: formState.inputs.lastname.value,
        registration: formState.inputs.registration.value,
        license_type: formState.inputs.license.value,
        company_id:
          auth.user.role !== "company"
            ? formState.inputs.company.value
            : auth.user.company.id,
        email: formState.inputs.email.value,
        password: formState.inputs.password.value,
        route_id: formState.inputs.route.value,
      },
      {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      }
    )

    return response
  } catch (err) {
    console.error("Error updating driver:", err)
    throw err // Propagate error to the caller
  }
}

export default updateEmployee
