import React, { useState, useEffect, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { NavLink } from "react-router-dom"
import { AuthContext } from "shared/context/auth-context"
import { mdiBookEdit, mdiDelete } from "@mdi/js"
import fetchCompanies from "shared/data/companies/fetchCompanies"
import fetchEmployees from "shared/data/employees/fetchEmployees"
import fetchEmployeesByCompany from "shared/data/employees/fetchEmployeesByCompany"
import Table from "shared/components/DataTable/Table"
import Icon from "@mdi/react"
import Loader from "shared/components/UIElements/Loader"
import axios from "axios"

const Employee = () => {
  const auth = useContext(AuthContext)
  const navigate = useNavigate()
  const [employees, setEmployees] = useState([])
  const [companies, setCompanies] = useState([])
  const [showModal, setShowModal] = useState("")
  const [isLoading, setIsLoading] = useState(null)
  const [employeeId, setUserId] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    document.title = "Empleados"
    if (!auth.token) {
      return
    }
    if (auth.user.role !== "company") {
      const loadCompanies = async () => {
        setIsLoading(true)
        try {
          const fetchedCompanies = await fetchCompanies(auth)
          setCompanies(fetchedCompanies)
        } catch (err) {
          if (err.response?.status === 401) {
            auth.logout()
          } else {
            setError(err.response?.data?.message || "An error occurred")
          }
        } finally {
          setIsLoading(false)
        }
      }
      loadCompanies()
    }
    if (auth.user.role !== "business") {
      const loadEmployees = async () => {
        const fetchedEmployees = await fetchEmployees(
          auth,
          navigate,
          setError,
          setIsLoading
        )
        setEmployees(fetchedEmployees)
      }
      loadEmployees()
    }
  }, [auth, navigate, setError, setIsLoading])

  const loadEmployeesByCompany = async (companyId) => {
    const fetchedEmployees = await fetchEmployeesByCompany(
      companyId,
      auth,
      navigate,
      setError,
      setIsLoading
    )
    setEmployees(fetchedEmployees)
  }

  const deactivateEmployee = async (id) => {
    closeModal()
    setUserId(null)
    setIsLoading(true)

    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
        baseURL: `${process.env.REACT_APP_API_URL}/employees/${id}`,
        method: "DELETE",
      })

      if (response.status === 200) {
        //getEmployees()
      } else {
        setError(response.data.error)
      }
    } catch (err) {
      setError(err.response.data.message)
    }
    setIsLoading(false)
  }

  const openModal = (id) => {
    setShowModal("is-active")
    setUserId(id)
    setError(null)
  }

  const closeModal = () => {
    setShowModal("")
  }

  const headers = [
    {
      Header: "Nombre(s)",
      accessor: "name",
    },
    {
      Header: "Apellidos",
      accessor: "lastname",
    },
    {
      Header: "Correo",
      accessor: "email",
    },
    {
      Header: "Compañía",
      accessor: "company.name",
    },
    {
      Header: "Código",
      accessor: "code",
    },
    {
      Header: "",
      accessor: "id",
      Cell: (row) => (
        <>
          <NavLink className="deactivate" to={`/employees/edit/${row.value}`}>
            <Icon path={mdiBookEdit} size={1}>
              Edit
            </Icon>
          </NavLink>
          <Icon
            className="deactivate"
            path={mdiDelete}
            size={1}
            onClick={() => {
              openModal(row.value)
            }}
          >
            Delete
          </Icon>
        </>
      ),
    },
  ]

  return (
    <div>
      <h1 className="title">Empleados</h1>
      <div className="actions">
        <NavLink to="/employees/create">
          <button className="button is-primary">Registrar Empleado</button>
        </NavLink>
      </div>
      <div>
        {isLoading && <Loader asOverlay />}
        <div className="card-mibus">
          {error && (
            <div className="columns">
              <div className="column">
                <div className="notification is-danger is-light">
                  Error: {error}
                </div>
              </div>
            </div>
          )}
          <Table
            dataCompanies={
              auth.user && auth.user.role !== "company" ? companies : null
            }
            cb={loadEmployeesByCompany}
            dataHeaders={headers}
            dataBody={employees}
          />
        </div>
      </div>

      <div className={`modal ${showModal}`}>
        <div className="modal-background"></div>
        <div className="modal-content">
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Confirmar</p>
              <button
                className="delete"
                aria-label="close"
                onClick={() => closeModal()}
              ></button>
            </header>
            <section className="modal-card-body">
              Confirma que deseas eliminar este empleado, esta acción solo puede
              revertirla un administrador.
            </section>
            <footer className="modal-card-foot">
              <button
                className="button is-danger"
                onClick={() => deactivateEmployee(employeeId)}
              >
                Eliminar Empleado
              </button>
              <button className="button" onClick={() => closeModal()}>
                Cancelar
              </button>
            </footer>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Employee
