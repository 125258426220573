import { React, useState, useContext, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { AuthContext } from "shared/context/auth-context"
import Loader from "shared/components/UIElements/Loader"
import Table from "shared/components/DataTable/Table"
import axios from "axios"

const ViewRoute = () => {
  const { id } = useParams()
  const auth = useContext(AuthContext)
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(null)
  const [error, setError] = useState(null)
  const [route, setRoute] = useState(null)
  const [checkpoints, setCheckpoints] = useState([])
  const [checkpointsEmployee, setCheckpointsEmployee] = useState([])
  const [usersOnRoute, setUsersOnRoute] = useState([])
  const [showModal, setShowModal] = useState("")
  const [currentTab, setCurrentTab] = useState(0)

  useEffect(() => {
    document.title = "Ver Historial de Ruta"
    if (!auth.token) {
      return
    }
    getCheckPoints()
    getCheckPointsEmployee()
    getRoute()
    getUsersOnRoute()
  }, [auth.token])

  const getUsersOnRoute = async () => {
    setIsLoading(true)

    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
        baseURL: `${process.env.REACT_APP_API_URL}/employees/route/${id}`,
        method: "GET",
      })

      if (response.status === 200) {
        setUsersOnRoute(response.data.employees)
      }
    } catch (err) {
      if (err.response.status === 401) {
        auth.logout()
      }
      setError(err.response.data.message)
    }
    setIsLoading(false)
  }

  const getRoute = async () => {
    setIsLoading(true)

    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
        baseURL: `${process.env.REACT_APP_API_URL}/routes/${id}`,
        method: "GET",
      })

      if (response.status === 200) {
        setRoute(response.data.route)
      }
    } catch (err) {
      if (err.response.status === 401) {
        auth.logout()
      }
      setError(err.response.data.message)
    }
    setIsLoading(false)
  }

  const getCheckPoints = async () => {
    setIsLoading(true)

    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
        baseURL: `${process.env.REACT_APP_API_URL}/checkpoints/${id}`,
        method: "GET",
      })

      if (response.status === 200) {
        setCheckpoints(response.data.checkpoints)
      }
    } catch (err) {
      if (err.response.status === 401) {
        auth.logout()
      }
      setError(err.response.data.message)
    }
    setIsLoading(false)
  }

  const getCheckPointsEmployee = async () => {
    setIsLoading(true)

    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
        baseURL: `${process.env.REACT_APP_API_URL}/checkpoints-employee/${id}`,
        method: "GET",
      })

      if (response.status === 200) {
        setCheckpointsEmployee(response.data.checkpoints)
      }
    } catch (err) {
      if (err.response.status === 401) {
        auth.logout()
      }
      setError(err.response.data.message)
    }
    setIsLoading(false)
  }

  const openModal = () => {
    setShowModal("is-active")
  }

  const closeModal = () => {
    setShowModal("")
  }

  const finishRoute = async () => {
    setIsLoading(true)

    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
        baseURL: `${process.env.REACT_APP_API_URL}/routes/finish/force/${id}`,
        method: "GET",
      })

      if (response.status === 200) {
        navigate("/routes", { replace: true })
      }
    } catch (err) {
      if (err.response.status === 401) {
        auth.logout()
      }
      setError(err.response.data.message)
    }
    setIsLoading(false)
  }

  const handleTabChange = (i) => {
    setCurrentTab(i)
  }

  const headers = [
    {
      Header: "Fecha y Hora",
      accessor: "datetime",
      Cell: (row) => {
        var d = new Date(row.value)
        var options = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        }
        return (
          <div>
            {d.toLocaleString("es-US", options).toString()} {/*row.value*/}
          </div>
        )
      },
    },
    {
      Header: "Ubicación",
      accessor: "name",
    },
    {
      Header: "Ruta Finalizada",
      accessor: "isEndRoute",
      Cell: (row) => {
        return row.value ? "Si" : "No"
      },
    },
  ]

  const headersEmployee = [
    {
      Header: "Fecha",
      accessor: "datetime",
      Cell: (row) => {
        var d = new Date(row.value)
        var options = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        }
        return <div>{d.toLocaleString("es-US", options).toString()}</div>
      },
    },
    {
      Header: "Nombre",
      accessor: "employee.name",
    },
    {
      Header: "Código",
      accessor: "employee.code",
    },
  ]

  const headersUsers = [
    {
      Header: "Nombre",
      accessor: "name",
    },
    {
      Header: "Apellido",
      accessor: "lastname",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Código",
      accessor: "code",
    },
  ]

  return (
    <div>
      <h1 className="title">
        Ver historial de Ruta <b>{route && route.name}</b>
      </h1>
      {route && (
        <div className="actions">
          {auth && route.active && (
            <button className="button is-danger" onClick={() => openModal()}>
              Forzar Terminar Ruta
            </button>
          )}
        </div>
      )}
      <div>
        {isLoading && <Loader asOverlay />}
        <div className="columns is-gapless">
          {route &&
            route.points.map((point) => {
              return (
                <div
                  className="column"
                  key={point.id}
                  style={{ textAlign: "center" }}
                >
                  <b>{point.name}</b>
                  {!point.checked && (
                    <div>
                      <div>
                        <img
                          style={{
                            width: "30px",
                            marginTop: "10px",
                          }}
                          src="https://cdn-icons-png.flaticon.com/512/787/787535.png"
                          alt=""
                        />
                      </div>
                      <div
                        style={{ backgroundColor: "#ed2a2a", height: "10px" }}
                      ></div>
                    </div>
                  )}
                  {point.checked && (
                    <div>
                      <div>
                        <img
                          style={{
                            width: "30px",
                            marginTop: "10px",
                          }}
                          src="https://cdn-icons-png.flaticon.com/512/1161/1161388.png"
                          alt=""
                        />
                      </div>
                      <div
                        style={{ backgroundColor: "#00b74c", height: "10px" }}
                      ></div>
                    </div>
                  )}
                </div>
              )
            })}
        </div>
        <div className="card-mibus">
          {error && (
            <div className="columns">
              <div className="column">
                <div className="notification is-danger is-light">
                  Error: {error}
                </div>
              </div>
            </div>
          )}
          <div className="tabs is-fullwidth">
            <ul>
              {[
                "Checkpoints",
                "Historial de ascenso/descenso",
                "Usuarios en Ruta",
              ].map((tab, index) => {
                return (
                  <li
                    key={index}
                    className={currentTab === index ? "is-active" : ""}
                  >
                    <a onClick={() => handleTabChange(index)}>{tab}</a>
                  </li>
                )
              })}
            </ul>
          </div>
          {currentTab === 0 && checkpoints.length > 0 && (
            <div>
              <Table
                dataBody={checkpoints}
                dataHeaders={headers}
                hasDateFilter={true}
              />
            </div>
          )}
          {currentTab === 1 && (
            <div>
              <Table
                dataBody={checkpointsEmployee}
                dataHeaders={headersEmployee}
                hasDateFilter={true}
              />
            </div>
          )}
          {currentTab === 2 && (
            <div>
              <div>
                <Table
                  dataBody={usersOnRoute}
                  dataHeaders={headersUsers}
                  hasDateFilter={true}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className={`modal ${showModal}`}>
        <div className="modal-background"></div>
        <div className="modal-content">
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Confirmar</p>
              <button
                className="delete"
                aria-label="close"
                onClick={() => closeModal()}
              ></button>
            </header>
            <section className="modal-card-body">
              Confirma que deseas terminar esta ruta
            </section>
            <footer className="modal-card-foot">
              <button
                className="button is-success"
                onClick={() => finishRoute()}
              >
                Terminar Ruta
              </button>
              <button className="button" onClick={() => closeModal()}>
                Cancelar
              </button>
            </footer>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewRoute
