import fetchData from "shared/data/fetchData"
import fetchDrivers from "./fetchDrivers"

const fetchDriversByCompany = async (
  companyId,
  auth,
  navigate,
  setError,
  setIsLoading
) => {
  if (companyId === "all") {
    return await fetchDrivers(auth, navigate, setError, setIsLoading)
  }

  setIsLoading(true)

  try {
    const drivers = await fetchData({
      endpoint: `drivers/company/${companyId}`,
      token: auth.token,
      dataKey: "drivers",
    })

    return drivers
  } catch (err) {
    if (err.response?.status === 401) {
      auth.logout()
    } else {
      setError(err.response?.data?.message || "Something went wrong")
    }
  } finally {
    setIsLoading(false)
  }
}

export default fetchDriversByCompany
